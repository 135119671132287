import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from 'crypto-js'
import { decryptAES } from "../utils/crypto";
import { getUserConnected } from "../api/ApiServices";

const checkAuth = () => {

/*  Getting token value stored in localstorage, if token is not present we will open login page 
    for all internal dashboard routes  */
    const secretKey = process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : '12345wqasdx9876zcbnmk89765'

    const TOKEN = localStorage.getItem("token")
    const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"];
    const token=Cookies.get('token');

    const token_example=getUserConnected();
    
    //const tokenDecrypte=decryptAES(token,secretKey);
    const tokenDecrypte="RFGSTbnhd58940884hbfFTSREV";
    //console.log(tokenDecrypte);
   
    const isPublicPage = PUBLIC_ROUTES.some( r => window.location.href.includes(r))

    //if(!TOKEN && !isPublicPage){
    if(!tokenDecrypte){
        window.location.href = '/login'
        return;
    }else{
        axios.defaults.headers.common['Authorization'] = `Bearer ${tokenDecrypte}`

        axios.interceptors.request.use(function (config) {
            // UPDATE: Add this code to show global loading indicator
            document.body.classList.add('loading-indicator');
            return config
          }, function (error) {
            return Promise.reject(error);
          });
          
          axios.interceptors.response.use(function (response) {
            // UPDATE: Add this code to hide global loading indicator
            document.body.classList.remove('loading-indicator');
            return response;
          }, function (error) {
            document.body.classList.remove('loading-indicator');
            return Promise.reject(error);
          });
        return tokenDecrypte;
    }
}

export default checkAuth