import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from "js-cookie";
import { INSCRIPTION } from '../../api/ApiUrls';
import { ENREGISTREMENT_ROLE } from '../../api/ApiUrls';
import { LISTE_ROLES } from '../../api/ApiUrls';


export const serviceSlice = createSlice({
    name: 'service',
    initialState: {
        isLoading: false,
        isSaving:false,
        error:null,
        roles : [],
        rolesFiltres : [],
        codeResult:600
    },
    reducers: {
        initialiser:(state, action)=>{
            state.isSaving =false
            state.error =null
            state.isSaving=false
            state.codeResult=600
            state.isLoading=false
        },
        
    },


})

export const {initialiser } = serviceSlice.actions

export default serviceSlice.reducer