import axiosClient from '../../api/AxiosClient';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ENREGISTREMENT_SOUSCATEGORIE, LISTE_SOUSCATEGORIES, MODIFIER_SOUSCATEGORIE , SUPPRIMER_SOUSCATEGORIE } from '../../api/ApiUrls';
import axios from 'axios';

export const getSouscategoriesContent = createAsyncThunk('/souscategories/content', async () => {
  let token=localStorage.getItem("cwpanel");
  const response = await axios.get(LISTE_SOUSCATEGORIES, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
  return response.data;
})

// Slice
const souscategorieSlice = createSlice({
  name: 'souscategorie',
  initialState: {
    souscategorie:{},
    souscategories:[],
    souscategoriesFiltres:[],
    status:"stop",
    isLoading: false,
    isLoadingSouscategorieFiltre:false,
    isSaving:false,
    error:null,
    codeResult:600
  },
  reducers: {
    
    registerSuccess: (state, action) =>  {
        state.souscategorie = action.payload;
        if(action.payload.status==405){
            state.error=action.payload.errors;
            state.codeResult=action.payload.status;
        }else if (action.payload.status==201) {
            state.codeResult=action.payload.status;
        }
    },
    updateSuccess: (state, action) =>  {
        state.souscategorie = action.payload;
        if(action.payload.status==405){
            state.error=action.payload.errors;
            state.codeResult=action.payload.status;
        }else if (action.payload.status==201) {
            state.codeResult=action.payload.status;
        }
    },
    deleteSuccess: (state, action) =>  {
        if(action.payload.status==405){
            state.error=action.payload.errors;
            state.codeResult=action.payload.status;
        }else if (action.payload.status==201) {
            state.codeResult=action.payload.status;
        }
    },
    renvoyerListeSousCategoriesFiltres: (state, action) =>  {

        state.souscategoriesFiltres =[]
        let sous_categorie_objet_tampon={}

        action.payload.forEach((element, index )=> {
            sous_categorie_objet_tampon={}
            sous_categorie_objet_tampon.name=element.libelle
            sous_categorie_objet_tampon.value=element.id
            state.souscategoriesFiltres = [...state.souscategoriesFiltres, sous_categorie_objet_tampon]
            if ((index+1)==action.payload.length) {
                state.isLoadingSouscategorieFiltre=true
            }
            
        });

    },
    reinitialiserSuccess: (state) =>  {
      state.souscategorie ={};
      state.status ="stop";
      state.codeResult=600;
      state.error=null;
    },
    reinitialiserLoadingSuccess: (state) =>  {
        state.isLoadingSouscategorieFiltre=false
      },
  
  },

    extraReducers:(builder)=>{
        builder.addCase(getSouscategoriesContent.pending, (state)=>{
            state.status = 'loading'
        }).addCase(getSouscategoriesContent.fulfilled,(state, action)=>{
            state.souscategories = action.payload.data
            state.status='looded'
        }).addCase(getSouscategoriesContent.rejected, (state)=>{
            state.status = 'error'
        })
    }

});
export default souscategorieSlice.reducer

// Actions
export const { reinitialiserSuccess, registerSuccess, updateSuccess, deleteSuccess, renvoyerListeSousCategoriesFiltres, reinitialiserLoadingSuccess} = souscategorieSlice.actions

export const reinitialiser = () => dispatch => {
  dispatch(reinitialiserSuccess());
}

export const reinitialiserLoadingSouscategorie=()=>dispatch=>{
    dispatch(reinitialiserLoadingSuccess());
}

export const registerSouscategorie = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(ENREGISTREMENT_SOUSCATEGORIE, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(registerSuccess(data)))
      .catch(error => console.error(error));
} 

export const updateSouscategorie = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(MODIFIER_SOUSCATEGORIE, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(updateSuccess(data)))
      .catch(error => console.error(error));
} 

export const deleteSouscategorie = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(SUPPRIMER_SOUSCATEGORIE+payload, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    }).then(response => response.json())
      .then(data => dispatch(deleteSuccess(data)))
      .catch(error => console.error(error));
}
 



