import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


export const getGroupeprivilegesContent = createAsyncThunk('/groupeprivilege/content', async () => {
    let token=localStorage.getItem("cwpanel");
	const response = await axios.get('http://127.0.0.1:8000/api/groupeprivilege/liste', {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
	return response.data;
})

export const groupeprivilegeSlice = createSlice({

    name: 'groupeprivileges',
    initialState: {
        isLoading: false,
        groupeprivileges : []
    },
    reducers: {

        addNewGroupeprivilege: (state, action) => {
            let {newLeadObj} = action.payload
            state.groupeprivileges = [...state.groupeprivileges, newLeadObj]
        },

        deleteGroupreprivilege: (state, action) => {
            let {index} = action.payload
            state.groupeprivileges.splice(index, 1)
        }
    },

    extraReducers: {
		[getGroupeprivilegesContent.pending]: state => {
			state.isLoading = true
		},
		[getGroupeprivilegesContent.fulfilled]: (state, action) => {
			state.groupeprivileges = action.payload.data
			state.isLoading = false
		},
		[getGroupeprivilegesContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewGroupreprivilege, deleteGroupreprivilege } = groupeprivilegeSlice.actions

export default groupeprivilegeSlice.reducer