import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
import { useAppContext } from './context';
import { LISTE_CONSTANTS } from './constants';
import PrivateRoute from './routes/PrivateRoute';
// Importing pages
const MainLayout = lazy(() => import('./containers/MainLayout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))
const Documentation = lazy(() => import('./pages/Documentation'))


// Initializing different libraries
initializeApp()


// Check for login and initialize axios
const token = checkAuth();


function App() {

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])

  const {connected}=useAppContext();

 //console.log(window.location.pathname);

  return (
    <>
      <Router>
        <Routes>
          
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          
          {/* Place new routes over this */}
          <Route path="/compte/*" element={<MainLayout />} />

          <Route path="/login" element={
            <PrivateRoute isConnected={connected} > 
              <Login/>
            </PrivateRoute>}
          />
          
          <Route path="*" element={<Navigate to={connected==LISTE_CONSTANTS.USER_CONNECTED ? "/compte/accueil" : "/login"} replace />}/>

        </Routes>
      </Router>
    </>
  )
}

export default App
