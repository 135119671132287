

export const sort=(tab)=>{
    for(var i = 0; i < tab.length; i++){
      //stocker l'index de l'élément minimum
      var min = i; 
      for(var j = i+1; j < tab.length; j++){
        if(tab[j].order < tab[min].order){
         // mettre à jour l'index de l'élément minimum
         min = j; 
        }
      }
      var tmp = tab[i].order;
      tab[i].order = tab[min].order;
      tab[min].order = tmp;
    }
    return tab;
  };
