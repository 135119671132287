import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import { ENREGISTREMENT_ROLE, MODIFIER_ROLE, SUPPRIMER_ROLE, VOIR_ROLE } from '../../api/ApiUrls';
import { LISTE_ROLES } from '../../api/ApiUrls';


export const getRolesContent = createAsyncThunk('/roles/content', async () => {
    let token=localStorage.getItem("cwpanel");
	const response = await axios.get(LISTE_ROLES, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
	return response.data;
})

export const registerRole = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(ENREGISTREMENT_ROLE, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(registerSuccess(data)))
      .catch(error => console.error(error));
}  

export const voirRole = (id) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(VOIR_ROLE+id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
      .then(data => dispatch(voirRoleSuccess({data})))
      .catch(error => console.error(error));
}  

export const supprimerRole = (id) => async dispatch =>{
   
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(SUPPRIMER_ROLE+id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
      .then(data => dispatch(supprimerRoleSuccess(data)))
      .catch(error => console.error(error));
}  


export const modifierRole = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(MODIFIER_ROLE, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(modifierRoleSuccess(data)))
      .catch(error => console.error(error));
} 

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        isLoadingRole: true,
        isSaving:false,
        error:null,
        roles : [],
        role : null,
        rolesFiltres : [],
        codeResult:600,
        groupeprivilegesFiltres:[],
        privileges:[],
    },
    reducers: {
        initialiser:(state, action)=>{
            state.isSaving =false
            state.error =null
            state.isSaving=false
            state.codeResult=600
            state.isLoadingRole=true
            state.role=null
            state.roles=[]
            state.groupeprivilegesFiltres=[]
            state.privileges=[]
        },
        initialiserUpdate:(state, action)=>{
            state.codeResult=600
            state.isSaving=false
            state.error=null
        }
        ,
        registerSuccess: (state, action) => {
            if(action.payload.status==405){
                state.isSaving =false
                state.codeResult=action.payload.status
                state.error =action.payload.errors
            }else if(action.payload.status==201){
                state.isSaving =true
                state.error =null
                state.codeResult=action.payload.status
            }
        },

        showSuccess: (state, action) => {

            state.privileges=action.payload.privileges;
            state.groupeprivilegesFiltres=[];
            
            action.payload.groupeprivileges && action.payload.groupeprivileges.forEach((groupeprivilege,index) => {
                
                let objet_groupe_tampon={}

                objet_groupe_tampon.code=groupeprivilege.code
                objet_groupe_tampon.mode=groupeprivilege.mode
                objet_groupe_tampon.titre_fr=groupeprivilege.titre_fr
                objet_groupe_tampon.titre_en=groupeprivilege.titre_en

                let privileges_array=[];

                groupeprivilege.privileges.forEach((privilege,index) => {

                    let objet_privileges_tampon={}
                    objet_privileges_tampon.id=privilege.id;
                    objet_privileges_tampon.code=privilege.code;
                    objet_privileges_tampon.titre=privilege.titre;

                    objet_privileges_tampon.isPrivilege=false;
                    action.payload.role.data.privileges.forEach((item_privilege) => {
                        if (item_privilege.code==privilege.code) {
                            objet_privileges_tampon.isPrivilege=true;
                        }
                    });

                    privileges_array = [...privileges_array, objet_privileges_tampon]

                });    

                objet_groupe_tampon.privileges=privileges_array

                state.groupeprivilegesFiltres = [...state.groupeprivilegesFiltres, objet_groupe_tampon]
            });

            //console.log(state.groupeprivilegesFiltres)

        },

        voirRoleSuccess: (state, action) => {
            state.role=action.payload.data;
            state.isLoadingRole=false;
        },
        supprimerRoleSuccess: (state, action) => {
           
            if(action.payload.status==405){
                state.error=action.payload.errors;
                state.codeResult=action.payload.status;
            }else if (action.payload.status==201) {
                state.codeResult=action.payload.status;
            }
        },
        modifierRoleSuccess: (state, action) => {
            if(action.payload.status==405){
                state.isSaving =false
                state.codeResult=action.payload.status
                state.error =action.payload.errors.titre
            }else if(action.payload.status==201){
                state.isSaving =true
                state.error =null
                state.codeResult=action.payload.status
            }
        },

        addNewLead: (state, action) => {
            let {newLeadObj} = action.payload
            state.roles = [...state.roles, newLeadObj]
        },

        filtrerRoles: (state, action) => {
            let {newLeadObj} = action.payload
            state.roles = [...state.roles, newLeadObj]
        },

        deleteLead: (state, action) => {
            let {index} = action.payload
            state.roles.splice(index, 1)
        }
    },

    extraReducers: {
		[getRolesContent.pending]: state => {
			state.isLoading = true
		},
		[getRolesContent.fulfilled]: (state, action) => {
            
            action.payload.data && action.payload.data.forEach((role,index) => {
                let objet_tampon={}
                objet_tampon.name=role.titre
                objet_tampon.value=role.id
                state.rolesFiltres = [...state.rolesFiltres, objet_tampon]
            });

			state.roles = action.payload.data
			state.isLoading = false
		},
		[getRolesContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewLead, deleteLead, registerSuccess, showSuccess, voirRoleSuccess, initialiser, initialiserUpdate, modifierRoleSuccess,supprimerRoleSuccess } = rolesSlice.actions

export default rolesSlice.reducer