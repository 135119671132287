import {createSlice} from "@reduxjs/toolkit";


const initialState = {
  codeleftlien_navigation:null,
  codeleftsouslien_navigation:null
}


export const leftnavigationSlice = createSlice({
  name: "leftnavigation",
  initialState,
  reducers:{
    
    updatecodeleftlien: (state, action)=>{
        state.codeleftlien_navigation=action.payload.code_lien;
        state.codeleftsouslien_navigation=action.payload.code_sous_lien;
    },


  }
})

export const {updatecodeleftlien} = leftnavigationSlice.actions;

export default leftnavigationSlice.reducer
