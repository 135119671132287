import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { LISTE_PAYS } from '../../api/ApiUrls';

export const getPaysContent = createAsyncThunk('/pays/content', async () => {
    let token=localStorage.getItem("cwpanel");
	const response = await axios.get(LISTE_PAYS, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
	return response.data;
})

export const paysSlice = createSlice({
    name: 'pays',
    initialState: {
        isLoading: false,
        pays : [],
        paysFiltres:[]
    },
    reducers: {

        addNewLink: (state, action) => {
            let {newLeadObj} = action.payload
            state.pays = [...state.pays, newLeadObj]
        },

        deleteLink: (state, action) => {
            let {index} = action.payload
            state.pays.splice(index, 1)
        },

        sendLinkValide:(state,action)=>{
            let {index} = action.payload
            //state.links.splice(index, 1)
        }


    },

    extraReducers: {
		[getPaysContent.pending]: state => {
			state.isLoading = true
		},
		[getPaysContent.fulfilled]: (state, action) => {
            //console.log(action.payload)
            action.payload.data && action.payload.data.forEach((pays,index) => {
                let objet_tampon={}
                objet_tampon.name=pays.titre
                objet_tampon.value=pays.id
                state.paysFiltres = [...state.paysFiltres, objet_tampon]
            });

			state.pays = action.payload.data;
			state.isLoading = false;
            
		},
		[getPaysContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewLink, deleteLink } = paysSlice.actions

export default paysSlice.reducer