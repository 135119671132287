import axios from "axios";

import { baseUrl } from "./ApiUrls";

const axiosClient=axios.create({
    baseURL:`${baseUrl}/api`,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosClient.interceptors.request.use(
    (config) => {
      const token=localStorage.getItem('ACCESS_TOKEN');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config
    },
    error => Promise.reject(error)
)

export default axiosClient;

