import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { LISTE_PIECES, LISTE_SERVICES } from '../../api/ApiUrls';

export const getNaturepieceContent = createAsyncThunk('/naturepiece/content', async () => {
    let token=localStorage.getItem("cwpanel");
	const response = await axios.get(LISTE_PIECES, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
	return response.data;
})

export const naturepieceSlice = createSlice({
    name: 'naturepiece',
    initialState: {
        isLoading: false,
        pieces : [],
        piecesFiltres : [],
        codeResult:600
    },
    reducers: {

        addNewLink: (state, action) => {
            let {newLeadObj} = action.payload
            state.pieces = [...state.pieces, newLeadObj]
        },

        deleteLink: (state, action) => {
            let {index} = action.payload
            state.pieces.splice(index, 1)
        },

        sendLinkValide:(state,action)=>{
            let {index} = action.payload
            //state.links.splice(index, 1)
        }


    },

    extraReducers: {
		[getNaturepieceContent.pending]: state => {
			state.isLoading = true
		},
		[getNaturepieceContent.fulfilled]: (state, action) => {

            action.payload.data && action.payload.data.forEach((piece,index) => {
                let objet_tampon={}
                objet_tampon.name=piece.titre
                objet_tampon.value=piece.id
                state.piecesFiltres = [...state.piecesFiltres, objet_tampon]
            });

			state.pieces = action.payload.data;
			state.isLoading = false
		},
		[getNaturepieceContent.rejected]: state => {
			state.isLoading = false
		},
    }


})

export const { addNewLink, deleteLink } = naturepieceSlice.actions

export default naturepieceSlice.reducer