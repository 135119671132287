import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import Cookies from "js-cookie";
import { ENREGISTREMENT_UTILISATEUR, MISEAJOUR_UTILISATEUR } from '../../api/ApiUrls';
import { LISTE_UTILISATEURS } from '../../api/ApiUrls';
import { VOIR_UTILISATEUR } from '../../api/ApiUrls';

export const getUtilisateursContent = createAsyncThunk('/utilisateurs/content', async () => {
    let token=localStorage.getItem("cwpanel");
	const response = await axios.get(LISTE_UTILISATEURS, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
	return response.data;
})


export const registerUser = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(ENREGISTREMENT_UTILISATEUR, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-API-KEY":"62e9afa9b26ec1658e4f7c572663a19b"
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(registerSuccess(data)))
      .catch(error => console.error(error));
} 

export const showUser = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(VOIR_UTILISATEUR+payload, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    }).then(response => response.json())
      .then(data => dispatch(showSuccess(data)))
      .catch(error => console.error(error));
} 

export const updateUser = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(MISEAJOUR_UTILISATEUR, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(updateSuccess(data)))
      .catch(error => console.error(error));
} 

export const utilisateursSlice = createSlice({
    name: 'utilisateurs',
    initialState: {
        isLoading: false,
        utilisateurs : [],
        utilisateur : {},
        utilisateursInitial : [],
        dataPdf:[],
        isSaving:false,
        isUpdating:false,
        error:null,
        codeResult:600,
    },
    reducers: {

        initialiser:(state, action)=>{
            state.isSaving =false
            state.error =null
            state.codeResult=600
            state.isLoading=false
        },

        registerSuccess: (state, action) => {
            if(action.payload.status==405){
                state.isSaving =false
                state.codeResult=action.payload.status
                state.error =action.payload.errors.email
            }else if(action.payload.status==201){
                state.isSaving =true
                state.error =null
                state.codeResult=action.payload.status
            }
        },
        showSuccess: (state, action) => {
            if(action.payload.status==405){
                state.error =action.payload.errors.email
            }else if(action.payload.status==200){
                state.utilisateur=action.payload.data
                state.isLoading =true
                state.error =null
            }
        },
        updateSuccess: (state, action) => {
            if(action.payload.status==405){
                state.isUpdating=false
                state.codeResult=action.payload.status
                state.error =action.payload.errors.email
            }else if(action.payload.status==201){
                state.isUpdating =true
                state.error =null
                state.codeResult=action.payload.status
            }
        },
        addNewLead: (state, action) => {
            let {newLeadObj} = action.payload
            state.utilisateurs = [...state.utilisateurs, newLeadObj]
        },
        filterTableUtilisateurs: (state, action) => {
       
            let temporairePdfData=[]
            let search=action.payload.searchValeur?.toLowerCase();
            let resultat= action.payload.utilisateurs?.filter((item)=>{
                return search===''?item:(item?.id==search || item?.nom_famille?.toLowerCase()?.includes(search) || item?.prenoms?.toLowerCase()?.includes(search) || item?.matricule?.toLowerCase()?.includes(search) || item?.numero_portable_1?.toLowerCase()?.includes(search) || item?.numero_portable_2?.toLowerCase()?.includes(search) || item?.emploi?.toLowerCase()?.includes(search) || item?.role?.titre?.toLowerCase()?.includes(search) || item?.statut?.toLowerCase()?.includes(search) )
            });
            state.utilisateurs =resultat;
            
            resultat.forEach((item,index) => {
                temporairePdfData.push(index+1);
                temporairePdfData.push(item.nom_famille);
                temporairePdfData.push(item.prenoms);
                temporairePdfData.push(item.matricule);
                temporairePdfData.push(item.numero_portable_1);
                //temporairePdfData.push(item.role.titre);
                temporairePdfData.push(item.emploi);
                temporairePdfData.push(item.statut);
            });
            state.dataPdf=temporairePdfData;

        },

        deleteLead: (state, action) => {
            let {index} = action.payload
            state.utilisateurs.splice(index, 1)
        }
    },

    extraReducers: {
		[getUtilisateursContent.pending]: state => {
			state.isLoading = true
		},
		[getUtilisateursContent.fulfilled]: (state, action) => {
			state.utilisateurs = action.payload.data
			state.isLoading = false
		},
		[getUtilisateursContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewLead, deleteLead, initialiser,registerSuccess, showSuccess, updateSuccess, filterTableUtilisateurs } = utilisateursSlice.actions

export default utilisateursSlice.reducer