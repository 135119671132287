  import { lazy } from 'react'

  export const listeConstants={
    privilege_dashboard_default:11,
    privilege_accueil_default:12,
  }


  export const listeRoutesConstants={
    'DASHBOARD':{
      path: '/tableau-de-bord', // the url
      component: 'Dashboard', // view rendered
    },
    'USERS':{
      path: '/utilisateurs',
      component: 'Utilisateurs',
    },
    'SEEUSER':{
      path: '/utilisateur/voir/:id',
      component: 'VoirUtilisateur',
    },
    'ADDUSER':{
      path: '/utilisateur/ajout',
      component: 'AjoutUtilisateur',
    },
    'MODIFYUSER':{
      path: '/utilisateur/modifier/:id',
      component: 'ModificationUtilisateur',
    },
    'DELETEUSER':{
      path: '/utilisateur/supprimer/:id',
      component: 'ModificationUtilisateur',
    },
    'ROLES':  {
      path: '/roles',
      component: 'Roles',
    },
    'ADDROLE':{
      path: '/role/ajout',
      component: 'AjoutRole',
    },

  }


  