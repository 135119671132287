
const NON_ACTIVE=0;
const ACTIVE=1;
const DESACTIVE=2;
const SUPPRIME=3;

//Statut Token
const VALIDE=1;
const EXPIRED=0;

//LOGIN
const CONNECTER=0;
const NONCONNECTER=1;

const RACINE_URL_IMAGE="http://localhost:8000/uploads/images/";

//const RACINE_URL_IMAGE="https://back.prepaconcours-ci.com/uploads/images/";

//Statuts abonnement
//Statut inactif
const INACTIF="inactif";
//Statut actif
const ACTIF="actif";
//Statut depasser
const DEPASSER="depasser";

const USER_CONNECTED="CONNECTED";
const USER_NOTCONNECTED="NOTCONNECTED";
const DATA_LOADING="LOADING";

//Nombre de thèmes disponible si l'utilisateur n'est pas connecte
const NOMBRE_THEME_USER_NONCONNECTE=3;

//Nombre de thèmes disponible si l'utilisateur est connecte
const NOMBRE_THEME_USER_CONNECTE=4;

//Liste des IDS
const NOMBRE_TOTAL_ITEM_BLOCKE=36;
const CODE_ITEM_BLOCKE="NONDISPONIBLE";
const ID_ITEM_BLOCKE=2024;

const VOIRDETAILS="voirdetails";
const ITEMSBLOQUE="itemsbloque";

//DUREE
const DUREEABONNEMENT6MOIS="6mois";
const DUREEABONNEMENT12MOIS="12mois";

//APPARTENANCE
const APPARTENANCE_SURPREPACONCOURS="PREPACONCOURS";
const APPARTENANCE_PAREMAIL="PAREMAIL";
const APPARTENANCE_PARTELEPHONE="PARTELEPHONE";
const NOTIFICATION_INTELLIGENTE="NOTIFICATIONINTELLIGENTE";

export const navlinks = [
    {
      name: "Home",
      subLinks: [
        { name: "Discover", to: "/discover", icon: "BiPlayCircle" },
        { name: "Browse", to: "/browse", icon: "RiListIndefinite" },
        { name: "Search", to: "/search", icon: "FaSearchengin" },
      ],
    },
    {
      name: "Your Library",
      subLinks: [
        {
          name: "Liked Songs",
          to: "/liked-songs",
          icon: "MdOutlineFavoriteBorder",
        },
        { name: "My Playlists", to: "/my-playlist", icon: "PiPlaylistBold" },
      ],
    },
    {
      name: "Account",
      subLinks: [
        { name: "Profile", to: "/profile", icon: "BiUser" },
        { name: "Logout", to: "/logout", icon: "MdLogout" },
      ],
    },
];
  
export const logo = {
    name: "Prepaconcoursci",
    icon: "PiMusicNoteFill",
};


export const codeLinks={
    accueil:"ACCUEIL",
    concours:"CONCOURS",
    formations:"FORMATIONS",
    apropos:"APROPOS",
    contact:"CONTACT"
}

export const codeSousLinks={
    users:"USERS",
    roles:"ROLES",
    listeproduits:"LISTEPRODUITS",
    ajoutproduit:"AJOUTERPRODUIT",
    categorieproduit:"CATEGORIEPRODUIT"
}

export const titreLinks={
    dashboard:"Tableau de bord",
    utilisateurs:"Les utilisateurs",
    roles:"Les rôles",
    produits:"Produits",
    categorieproduit:"Les categories de produit"
}

const listeConstants={
    privilege_dashboard_default:11,
    privilege_accueil_default:12,
}


const listeRoutesConstants={
    'DASHBOARD':{
      path: '/tableau-de-bord', // the url
      component: 'Dashboard', // view rendered
    },
    'USERS':{
      path: '/utilisateurs',
      component: 'Utilisateurs',
    },
    'SEEUSER':{
      path: '/utilisateur/voir/:id',
      component: 'VoirUtilisateur',
    },
    'ADDUSER':{
      path: '/utilisateur/ajout',
      component: 'AjoutUtilisateur',
    },
    'MODIFYUSER':{
      path: '/utilisateur/modifier/:id',
      component: 'ModificationUtilisateur',
    },
    'DELETEUSER':{
      path: '/utilisateur/supprimer/:id',
      component: 'ModificationUtilisateur',
    },
    'ROLES':  {
      path: '/roles',
      component: 'Roles',
    },
    'ADDROLE':{
      path: '/role/ajout',
      component: 'AjoutRole',
    },

}


export const LISTE_CONSTANTS={  
                                listeRoutesConstants,
                                NOTIFICATION_INTELLIGENTE,
                                APPARTENANCE_SURPREPACONCOURS,
                                APPARTENANCE_PAREMAIL,
                                APPARTENANCE_PARTELEPHONE,
                                DUREEABONNEMENT6MOIS,
                                DUREEABONNEMENT12MOIS,
                                ITEMSBLOQUE,
                                VOIRDETAILS,
                                CODE_ITEM_BLOCKE,
                                ID_ITEM_BLOCKE,
                                RACINE_URL_IMAGE, 
                                INACTIF, ACTIF, DEPASSER, USER_CONNECTED, 
                                USER_NOTCONNECTED, NOMBRE_THEME_USER_NONCONNECTE,
                                NOMBRE_THEME_USER_CONNECTE,NOMBRE_TOTAL_ITEM_BLOCKE 
                            };


