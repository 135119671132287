import axiosClient from '../../api/AxiosClient';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ENREGISTREMENT_NIVEAU, LISTE_NIVEAUX, MODIFIER_NIVEAU , SUPPRIMER_NIVEAU } from '../../api/ApiUrls';
import axios from 'axios';

export const getNiveauxContent = createAsyncThunk('/niveaux/content', async () => {
  let token=localStorage.getItem("cwpanel");
  const response = await axios.get(LISTE_NIVEAUX, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
  return response.data;
})

// Slice
const niveauSlice = createSlice({
  name: 'niveau',
  initialState: {
    niveau:{},
    niveaux:[],
    niveauxFiltres:[],
    isLoadingNiveauxFiltres:false,
    status:"stop",
    isLoading: false,
    isSaving:false,
    error:null,
    codeResult:600
  },
  reducers: {
    
    registerSuccess: (state, action) =>  {
        state.niveau = action.payload;
        if(action.payload.status==405){
            state.error=action.payload.errors;
            state.codeResult=action.payload.status;
        }else if (action.payload.status==201) {
            state.codeResult=action.payload.status;
        }
    },
    updateSuccess: (state, action) =>  {
        state.niveau = action.payload;
        if(action.payload.status==405){
            state.error=action.payload.errors;
            state.codeResult=action.payload.status;
        }else if (action.payload.status==201) {
            state.codeResult=action.payload.status;
        }
    },
    deleteSuccess: (state, action) =>  {
        if(action.payload.status==405){
            state.error=action.payload.errors;
            state.codeResult=action.payload.status;
        }else if (action.payload.status==201) {
            state.codeResult=action.payload.status;
        }
    },
    reinitialiserSuccess: (state) =>  {
      state.niveau ={};
      state.status ="stop";
      state.codeResult=600;
      state.error=null;
    },
    renvoyerListeNiveauxFiltres: (state, action) =>  {
        state.niveauxFiltres =[]
        let niveau_objet_tampon={}
        action.payload.forEach((element,index) => {
            niveau_objet_tampon={}
            niveau_objet_tampon.name=element.libelle
            niveau_objet_tampon.value=element.id
            state.niveauxFiltres = [...state.niveauxFiltres, niveau_objet_tampon]
            if (action.payload.length==(index+1)) {
                state.isLoadingNiveauxFiltres=true
            }
        });
    },

    },
    
    extraReducers:(builder)=>{
        builder.addCase(getNiveauxContent.pending, (state)=>{
            state.status = 'loading'
        }).addCase(getNiveauxContent.fulfilled,(state, action)=>{
            state.niveaux = action.payload.data
            state.status='looded'
        }).addCase(getNiveauxContent.rejected, (state)=>{
            state.status = 'error'
        })
    }

});
export default niveauSlice.reducer

// Actions
export const { reinitialiserSuccess, registerSuccess, updateSuccess, deleteSuccess, renvoyerListeNiveauxFiltres} = niveauSlice.actions

export const reinitialiser = () => dispatch => {
  dispatch(reinitialiserSuccess());
}

export const registerNiveau = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(ENREGISTREMENT_NIVEAU, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(registerSuccess(data)))
      .catch(error => console.error(error));
} 

export const updateNiveau = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(MODIFIER_NIVEAU, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(updateSuccess(data)))
      .catch(error => console.error(error));
} 

export const deleteNiveau = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(SUPPRIMER_NIVEAU+payload, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    }).then(response => response.json())
      .then(data => dispatch(deleteSuccess(data)))
      .catch(error => console.error(error));
}
 



