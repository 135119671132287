import { createContext, useEffect, useState } from "react";
import { getUserConnected } from "../api/ApiServices";
import Cookies from "js-cookie";
import moment from 'moment'
import { LISTE_CONSTANTS } from "../constants";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  
  const [notification, _setNotification]=useState('');
  const [notificationError, _setNotificationError]=useState('');

  const [token, setToken] = useState(localStorage.getItem("cwpanel"));

  const [connected, setConnected]=useState("LOADING");
  const [stopChargement, setStopChargement]=useState(false);
  const [coderole, setCoderole]=useState("");
  const [items_disponible, setItems_disponible]=useState([]);
  const [user_abonnements, setUser_abonnements]=useState([]);
  const [userInfo, setUserInfo]=useState({});
  const [enregistrement_data, setEnregistrement_data]=useState(false);

  const setNotification=(message)=>{
      _setNotification(message);
      setTimeout(() => {
          _setNotification('');
      }, 8000);
  }

  const setNotificationError=(message)=>{
      _setNotificationError(message);
      setTimeout(() => {
          _setNotificationError('');
      }, 5000);
  }

  useEffect(()=>{

        (async () => {

          if(token){

            try {
              const userResponse = await getUserConnected();
              setUserInfo(userResponse.user);
              setCoderole(userResponse?.user?.role?.code);
              if( userResponse?.status === 200) {
                setConnected("CONNECTED");
              }else if(userResponse?.status === 404){
                setConnected("NOTCONNECTED");
                setStopChargement(true);
                if(token){
                    localStorage.removeItem("ACCESS_TOKEN");
                }
              }
            } catch (err) {
              console.log(err);
            } finally {
              //setLoadingCart(false);
            }

          }else{
            setConnected("NOTCONNECTED");
            setStopChargement(true);
          }
            
          })();

  },[])

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    setToken(null);
  };


  return (
    <AppContext.Provider
      value={{
        token,
        logoutHandler,
        userInfo,
        setUserInfo,
        connected,
        setConnected,
        enregistrement_data,
        setEnregistrement_data,
        notification,
        notificationError,
        setNotification,
        setNotificationError,
      }}
    >
      {children}
    </AppContext.Provider>
  );

  
};

export default AppContextProvider;
