import { Navigate } from "react-router-dom";
//import { Outlet } from "react-router-dom";
//import { NotFound } from "../pages/notfound/NotPage";
import { useNavigate } from "react-router-dom";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';


const PrivateRoute = ({isAllowed,children,isLoading,coderole,isConnected}) => {

    const navigate=useNavigate()

    if(isConnected=="LOADING"){
      return <div className='text-center mb-4 mt-4'>
      <CircularProgress isIndeterminate color='blue.400' />
    </div>
    }else if(isConnected=="NOTCONNECTED"){
      return children ;
    }else if(isConnected=="CONNECTED"){
      return <Navigate to={"/compte/accueil"} />;
    }
    
  };

  export default PrivateRoute;