import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from "js-cookie";
import { LISTE_SERVICES } from '../../api/ApiUrls';

export const getServiceContent = createAsyncThunk('/departement/content', async () => {
    let token=Cookies.get("token");
	const response = await axios.get(LISTE_SERVICES, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
	return response.data;
})

export const departementsSlice = createSlice({
    name: 'departements',
    initialState: {
        isLoading: false,
        services : [],
        servicesFiltres : [],
        codeResult:600
    },
    reducers: {

        addNewLink: (state, action) => {
            let {newLeadObj} = action.payload
            state.services = [...state.services, newLeadObj]
        },

        deleteLink: (state, action) => {
            let {index} = action.payload
            state.services.splice(index, 1)
        },

        sendLinkValide:(state,action)=>{
            let {index} = action.payload
            //state.links.splice(index, 1)
        }


    },

    extraReducers: {
		[getServiceContent.pending]: state => {
			state.isLoading = true
		},
		[getServiceContent.fulfilled]: (state, action) => {

            action.payload.data && action.payload.data.forEach((service,index) => {
                let objet_tampon={}
                objet_tampon.name=service.titre
                objet_tampon.value=service.id
                state.servicesFiltres = [...state.servicesFiltres, objet_tampon]
            });

			state.services = action.payload.data;
			state.isLoading = false
		},
		[getServiceContent.rejected]: state => {
			state.isLoading = false
		},
    }


})

export const { addNewLink, deleteLink } = departementsSlice.actions

export default departementsSlice.reducer