import axiosClient from '../../api/AxiosClient';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ENREGISTREMENT_CATEGORIE, MODIFIER_CATEGORIE, SUPPRIMER_CATEGORIE } from '../../api/ApiUrls';
import axios from 'axios';
import { LISTE_CATEGORIES } from '../../api/ApiUrls';


export const getCategoriesContent = createAsyncThunk('/categories/content', async () => {
  let token=localStorage.getItem("cwpanel");
  const response = await axios.get(LISTE_CATEGORIES, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
  return response.data;
})

// Slice
const categorieSlice = createSlice({
  name: 'categorie',
  initialState: {
    categorie:{},
    categories:[],
    status:"stop",
    isLoading: false,
    isSaving:false,
    error:null,
    codeResult:600
  },
  reducers: {
    
    registerSuccess: (state, action) =>  {
        state.categorie = action.payload;
        if(action.payload.status==405){
            state.error=action.payload.errors;
            state.codeResult=action.payload.status;
        }else if (action.payload.status==201) {
            state.codeResult=action.payload.status;
        }
    },
    updateSuccess: (state, action) =>  {
        state.categorie = action.payload;
        if(action.payload.status==405){
            state.error=action.payload.errors;
            state.codeResult=action.payload.status;
        }else if (action.payload.status==201) {
            state.codeResult=action.payload.status;
        }
    },
    deleteSuccess: (state, action) =>  {
        if(action.payload.status==405){
            state.error=action.payload.errors;
            state.codeResult=action.payload.status;
        }else if (action.payload.status==201) {
            state.codeResult=action.payload.status;
        }
    },
    reinitialiserSuccess: (state) =>  {
      state.categorie ={};
      state.status ="stop";
      state.codeResult=600;
      state.error=null;
    },

  },

  extraReducers:(builder)=>{
    builder.addCase(getCategoriesContent.pending, (state)=>{
        state.status = 'loading'
    }).addCase(getCategoriesContent.fulfilled,(state, action)=>{

        state.categories = action.payload.data
        state.status='looded'
        
    }).addCase(getCategoriesContent.rejected, (state)=>{
        state.status = 'error'
    })
}

});
export default categorieSlice.reducer

// Actions
const { reinitialiserSuccess, registerSuccess, updateSuccess, deleteSuccess} = categorieSlice.actions

export const reinitialiser = () => dispatch => {
  dispatch(reinitialiserSuccess());
}

export const registerCategorie = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(ENREGISTREMENT_CATEGORIE, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(registerSuccess(data)))
      .catch(error => console.error(error));
} 

export const updateCategorie = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(MODIFIER_CATEGORIE, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(updateSuccess(data)))
      .catch(error => console.error(error));
} 

export const deleteCategorie = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(SUPPRIMER_CATEGORIE+payload, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    }).then(response => response.json())
      .then(data => dispatch(deleteSuccess(data)))
      .catch(error => console.error(error));
}
 



