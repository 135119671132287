import axiosClient from '../../api/AxiosClient';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ENREGISTREMENT_NIVEAU, LISTE_MARQUES, MODIFIER_NIVEAU , SUPPRIMER_NIVEAU } from '../../api/ApiUrls';
import axios from 'axios';

export const getMarquesContent = createAsyncThunk('/marques/content', async () => {
  let token=localStorage.getItem("cwpanel");
  const response = await axios.get(LISTE_MARQUES, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
  return response.data;
})

// Slice
const marqueSlice = createSlice({
  name: 'marque',
  initialState: {
    marque:{},
    marques:[],
    marquesFiltres:[],
    isLoadingMarquesFiltres:false,
    status:"stop",
    isLoading: false,
    isSaving:false,
    error:null,
    codeResult:600
  },
  reducers: {
    
        registerSuccess: (state, action) =>  {
            state.marque = action.payload;
            if(action.payload.status==405){
                state.error=action.payload.errors;
                state.codeResult=action.payload.status;
            }else if (action.payload.status==201) {
                state.codeResult=action.payload.status;
            }
        },
        updateSuccess: (state, action) =>  {
            state.marque = action.payload;
            if(action.payload.status==405){
                state.error=action.payload.errors;
                state.codeResult=action.payload.status;
            }else if (action.payload.status==201) {
                state.codeResult=action.payload.status;
            }
        },
        deleteSuccess: (state, action) =>  {
            if(action.payload.status==405){
                state.error=action.payload.errors;
                state.codeResult=action.payload.status;
            }else if (action.payload.status==201) {
                state.codeResult=action.payload.status;
            }
        },
        reinitialiserSuccess: (state) =>  {
            state.marque ={};
            state.status ="stop";
            state.codeResult=600;
            state.error=null;
        },
        renvoyerListeMarquesFiltres: (state, action) =>  {
            state.marquesFiltres =[]
            let marque_objet_tampon={}
            action.payload.forEach((element,index) => {
                marque_objet_tampon={}
                marque_objet_tampon.name=element.libelle
                marque_objet_tampon.value=element.id
                state.marquesFiltres = [...state.marquesFiltres, marque_objet_tampon]
                if (action.payload.length==(index+1)) {
                    state.isLoadingMarquesFiltres=true
                }
            });
        },

    },
    
    extraReducers:(builder)=>{
        builder.addCase(getMarquesContent.pending, (state)=>{
            state.status = 'loading'
        }).addCase(getMarquesContent.fulfilled,(state, action)=>{
            state.marques = action.payload.data
            state.status='looded'
        }).addCase(getMarquesContent.rejected, (state)=>{
            state.status = 'error'
        })
    }

});
export default marqueSlice.reducer

// Actions
export const { reinitialiserSuccess, registerSuccess, updateSuccess, deleteSuccess, renvoyerListeMarquesFiltres} = marqueSlice.actions

export const reinitialiser = () => dispatch => {
  dispatch(reinitialiserSuccess());
}

export const registerMarque = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(ENREGISTREMENT_NIVEAU, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(registerSuccess(data)))
      .catch(error => console.error(error));
} 

export const updateMarque = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(MODIFIER_NIVEAU, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(updateSuccess(data)))
      .catch(error => console.error(error));
} 

export const deleteMarque = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(SUPPRIMER_NIVEAU+payload, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    }).then(response => response.json())
      .then(data => dispatch(deleteSuccess(data)))
      .catch(error => console.error(error));
}
 



