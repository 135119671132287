import axiosClient from '../../api/AxiosClient';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ENREGISTREMENT_PRODUIT, LISTE_PRODUITS, MODIFIER_PRODUIT, SUPPRIMER_PRODUIT, VOIR_PRODUIT } from '../../api/ApiUrls';
import axios from 'axios';


export const getProduitsContent = createAsyncThunk('/produits/content', async () => {
  let token=localStorage.getItem("cwpanel");
  const response = await axios.get(LISTE_PRODUITS, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
  return response.data;
})

export const registerProduit = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(ENREGISTREMENT_PRODUIT, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(registerSuccess(data)))
      .catch(error => console.error(error));
}  

export const montrerProduit = (id) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(VOIR_PRODUIT+id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
      .then(data => dispatch(showSuccess(data)))
      .catch(error => console.error(error));
} 

export const updateProduit = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(MODIFIER_PRODUIT, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(updateSuccess(data)))
      .catch(error => console.error(error));
} 

export const deleteProduit = (id) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(SUPPRIMER_PRODUIT+id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
      .then(data => dispatch(deleteSuccess(data)))
      .catch(error => console.error(error));
} 

// Slice
const produitsSlice = createSlice({
  name: 'produit',
  initialState: {
    status:"stop",
    listeCategories:[],
    listeProduitsFiltres:[],
    isLoadingListeCategorie:false,
    anneeStockFiltre:null,
    codeResult:600,
    codeResultShow:600,
    isCreate_button:true,
    dateStockInitial:null,
    dateAnneeNouvelle:null,
    isDateAnneeNouvelle:false,
    error:null,
    cles:null,
  },
  reducers: {

    showSuccess: (state, action) =>  {
        if(action.payload.status==405){
            state.codeResultShow = action.payload.status;
        }else if(action.payload.status==200){
            state.codeResultShow = action.payload.status;
        } 
    },
    registerSuccess: (state, action) =>  {
        
        if(action.payload.status==405){
            state.error = action.payload.errors;
            state.cles = action.payload.cles;
            state.codeResult = action.payload.status;
        }else if(action.payload.status==201){
            state.codeResult = action.payload.status;
        }
    },
    updateSuccess: (state, action) =>  {
        
        if(action.payload.status==405){
            state.error = action.payload.errors;
            state.cles = action.payload.cles;
            state.codeResult = action.payload.status;
        }else if(action.payload.status==201){
            state.codeResult = action.payload.status;
        }
    },
    deleteSuccess: (state, action) =>  {
        
        if(action.payload.status==405){
            state.error = action.payload.errors;
            state.cles = action.payload.cles;
            state.codeResult = action.payload.status;
        }else if(action.payload.status==201){
            state.codeResult = action.payload.status;
        }
    },
    renvoyerListeCategories: (state, action) =>  {

        state.listeCategories =[]
        let categorie_objet_tampon={}
        action.payload.forEach((element,index) => {
            categorie_objet_tampon={}
            categorie_objet_tampon.name=element.libelle
            categorie_objet_tampon.value=element.id
            state.listeCategories = [...state.listeCategories, categorie_objet_tampon]
            if (action.payload.length==(index+1)) {
                state.isLoadingListeCategorie=true
            }
        });

    },
    creerDateStockinitial:(state,action)=>{
        if(state.isCreate_button){
            state.isCreate_button=false;
        }else{
            state.isCreate_button=true;
        }
    },
    fermerModalDateStockinitial:(state,action)=>{
        state.isCreate_button=false
    },
    ajouterDateStockinitial:(state,action)=>{
        console.log(action.payload)
    },
    reinitialiserSuccess: (state) =>  {
        state.error = null;
        state.cles = null;
        state.codeResult = 600;
        state.status="stop";
        state.isCreate_button=true
    },
    maintenirCreate_button:(state)=>{
        state.isCreate_button=false
    },
    envoyerDateSuccess:(state, action)=>{
        state.dateAnneeNouvelle=action.payload.anneeNouvelle;
        state.isDateAnneeNouvelle=true;
    },
    reinitialiserDateSuccess: (state) =>  {
        state.dateAnneeNouvelle=null;
        state.isDateAnneeNouvelle=false;
    },
},

extraReducers:(builder)=>{
    builder.addCase(getProduitsContent.pending, (state)=>{
        state.status = 'loading'
    }).addCase(getProduitsContent.fulfilled,(state, action)=>{

        state.listeProduitsFiltres =[]

        action.payload.data && action.payload.data.forEach((produit,index) => {

            let objet_tampon={}
            objet_tampon.id=produit.id
            objet_tampon.libelle=produit.libelle
            objet_tampon.description=produit.description
            objet_tampon.code=produit.code
            objet_tampon.mode=produit.mode
            objet_tampon.reference=produit.reference 
            objet_tampon.numero_compte=produit.numero_compte
            objet_tampon.poids=produit.poids 
            objet_tampon.numero_serie=produit.numero_serie
            objet_tampon.prix_achat_unitaire=produit.prix_achat_unitaire
            objet_tampon.prix_vente=produit.prix_vente
            objet_tampon.cree_par=produit.cree_par
            objet_tampon.cree_le=produit.cree_le 
            objet_tampon.modifie_par=produit.modifie_par
            objet_tampon.modifie_le=produit.modifie_le

            objet_tampon.marque=produit.marque?produit.marque:null;
            objet_tampon.type_code_barre=produit.type_code_barre?produit.type_code_barre:null;
            objet_tampon.categorie=produit.categorie?produit.categorie:null;
            objet_tampon.souscategorie=produit.souscategorie?produit.souscategorie:null;
            objet_tampon.niveau=produit.niveau?produit.niveau:null;
            objet_tampon.unite=produit.unite?produit.unite:null;

            if (produit.annee_stock_quantite) {
                objet_tampon.annee_stock_quantite=produit.annee_stock_quantite
            }else{
                objet_tampon.annee_stock_quantite=null;
            }
           

            state.listeProduitsFiltres = [...state.listeProduitsFiltres, objet_tampon]

        });

        state.status='looded'
        
    }).addCase(getProduitsContent.rejected, (state)=>{
        state.status = 'error'
    })
}

});
export default produitsSlice.reducer

// Actions
export const { showSuccess, updateSuccess, deleteSuccess, envoyerDateSuccess,maintenirCreate_button, reinitialiserDateSuccess, reinitialiserSuccess, registerSuccess, renvoyerListeCategories, creerDateStockinitial, fermerModalDateStockinitial} = produitsSlice.actions

export const reinitialiser = () => dispatch => {
  dispatch(reinitialiserSuccess());
}

export const reinitialiserDate = () => dispatch => {
    dispatch(reinitialiserDateSuccess());
}
 



