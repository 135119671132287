import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leadsSlice from '../features/leads/leadSlice'
import roleSlice from '../features/roles/roleSlice'
import authSlice from '../features/auth/authSlice'
import linksSlice from '../features/links/linksSlice'
import leftnavigationSlice from '../features/leftnavigation/leftnavigationSlice'
import groupeprivilegeSlice from '../features/groupeprivileges/groupeprivilegeSlice'
import utilisateurSlice from '../features/utilisateur/utilisateurSlice'
import { serviceSlice } from '../features/services/serviceSlice'
import departementsSlice from '../features/departements/departementsSlice'
import naturepieceSlice from '../features/naturepiece/naturepieceSlice'
import villesSlice from '../features/villes/villesSlice'
import paysSlice from '../features/pays/paysSlice'
import souslienSlice from '../features/auth/souslienSlice'
import categoriesSlice from '../features/categories/categoriesSlice'
import souscategorieSlice from '../features/souscategories/souscategorieSlice'
import produitsSlice from '../features/produits/produitsSlice'
import NiveauSlice from '../features/niveau/NiveauSlice'
import marqueSlice from '../features/marque/marqueSlice'

const combinedReducer = {
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  lead : leadsSlice,
  role:roleSlice,
  auth:authSlice, 
  links:linksSlice,
  utilisateur:utilisateurSlice,
  service:serviceSlice, 
  ville:villesSlice,
  pays:paysSlice,
  departement:departementsSlice,
  leftnavigation:leftnavigationSlice,
  groupeprivilege:groupeprivilegeSlice,
  naturepiece:naturepieceSlice,
  souslien:souslienSlice,
  categorie:categoriesSlice,
  souscategorie:souscategorieSlice,
  produit:produitsSlice,
  niveau:NiveauSlice,
  marque:marqueSlice
}


export default configureStore({
    reducer: combinedReducer
})