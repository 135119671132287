import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { LISTE_VILLES } from '../../api/ApiUrls';

export const getVillesContent = createAsyncThunk('/villes/content', async () => {
    let token=localStorage.getItem("cwpanel");
	const response = await axios.get(LISTE_VILLES, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
	return response.data;
})

export const villesSlice = createSlice({
    name: 'villes',
    initialState: {
        isLoading: false,
        villes : [],
        villesFiltres:[]
    },
    reducers: {

        addNewLink: (state, action) => {
            let {newLeadObj} = action.payload
            state.villes = [...state.villes, newLeadObj]
        },

        deleteLink: (state, action) => {
            let {index} = action.payload
            state.villes.splice(index, 1)
        },

        sendLinkValide:(state,action)=>{
            let {index} = action.payload
            //state.links.splice(index, 1)
        }


    },

    extraReducers: {
		[getVillesContent.pending]: state => {
			state.isLoading = true
		},
		[getVillesContent.fulfilled]: (state, action) => {

            action.payload.data && action.payload.data.forEach((ville,index) => {
                let objet_tampon={}
                objet_tampon.name=ville.titre
                objet_tampon.value=ville.id
                state.villesFiltres = [...state.villesFiltres, objet_tampon]
            });

			state.villes = action.payload.data;
			state.isLoading = false;
            
		},
		[getVillesContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewLink, deleteLink } = villesSlice.actions

export default villesSlice.reducer