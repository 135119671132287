import axiosClient from '../../api/AxiosClient';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CONNEXION , CURRENT_USER_CONNECTED_URL, DECONNEXION, INSCRIPTION } from '../../api/ApiUrls';
import axios from 'axios';
import { listeRoutesConstants } from '../../constants/listeConstants';


export const getSousliensContent = createAsyncThunk('/souslien/content', async () => {
  let token=localStorage.getItem("cwpanel");
  const response = await axios.get(CURRENT_USER_CONNECTED_URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
  return response.data;
})

// Slice
const souslienSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: null,
    auth_connexion:null,
    connected:false,
    status:"stop",
    lienNavigationSidebar:[],
    sousLienNavigation:[],
    sousLienNavigationInterior:[],
    privileges:[],
    privileges_id:[]
  },
  reducers: {
    loginSuccess: (state, action) => {
        state.auth = action.payload;
    },
    logoutSuccess: (state, action) =>  {
      state.auth = action.payload;
    },
    registerSuccess: (state, action) =>  {
      state.auth = action.payload;
    },
    reinitialiserSuccess: (state) =>  {
      state.auth =null;
    },
  },

  extraReducers:(builder)=>{
    builder.addCase(getSousliensContent.pending, (state)=>{
        state.status = 'loading'
    }).addCase(getSousliensContent.fulfilled,(state, action)=>{
 
        action.payload.user.role && action.payload.user.role.privileges.forEach((privilege,index) => {

          let objet_privilege_tampon=""
          let objet_privilege_id_tampon=""

          if(privilege.mode==1){
            objet_privilege_tampon=privilege.code
            objet_privilege_id_tampon=privilege.id
            state.privileges = [...state.privileges, objet_privilege_tampon]
            state.privileges_id = [...state.privileges_id, objet_privilege_id_tampon]
          }

          if(privilege.sous_lien_navigation){
            state.sousLienNavigation = [...state.sousLienNavigation, privilege.sous_lien_navigation.code]
          }

        });

        state.status='looded'
        
    }).addCase(getSousliensContent.rejected, (state)=>{
        state.status = 'error'
    })
}

});
export default souslienSlice.reducer

// Actions
const { loginSuccess, logoutSuccess, reinitialiserSuccess, registerSuccess} = souslienSlice.actions

export const reinitialiser = () => dispatch => {
  dispatch(reinitialiserSuccess());
}
 



