import axios from "axios";
import {
  CART_URL,
  PRODUCTS_URL,
  CONCOURS_URL,
  EPREUVE_URL,
  LOGIN_URL,
  SIGNUP_URL,
  WISHLIST_URL,
  CATEGORIES_URL,
  CURRENT_USER_CONNECTED_URL,
  ENREGISTREMENT_ABONNEMENT,
  AFFICHER_TOUS_LES_CONCOURS_URL,
  AFFICHER_TOUS_LES_CONCOURS_BY_TYPE_URL,
} from "./ApiUrls";
import Cookies from "js-cookie";

export const loginService = (email, password) =>axios.post(LOGIN_URL, { email, password });

export const signupService = (username, email, password) =>axios.post(SIGNUP_URL, { username, email, password });

export const getAllProductsService = () => axios.get(PRODUCTS_URL);

export const getProductByIdService = (productId) =>axios.get(`${PRODUCTS_URL}/${productId}`);

export const getConcoursByIdService = (concoursId) =>axios.get(`${CONCOURS_URL}/${concoursId}`);

export const getEpreuveByIdService = (epreuveId) =>axios.get(`${EPREUVE_URL}/${epreuveId}`);

export async function getListeConcoursActive(){
  const response=await fetch(AFFICHER_TOUS_LES_CONCOURS_URL, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
      }
  });
  const data = await response.json();
  return data;
}  

export async function getListeConcoursActiveByType(type_id){
  const response=await fetch(AFFICHER_TOUS_LES_CONCOURS_BY_TYPE_URL+`/${type_id}`, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
      }
  });
  const data = await response.json();
  return data;
}  

export const getCartItemsService = (token) =>
  axios.get(CART_URL, {
    headers: {
      authorization: token,
    },
  });

let token=localStorage.getItem("cwpanel");

export async function getUserConnected_2(){
    const response=await fetch(CURRENT_USER_CONNECTED_URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
    const utilisateur = await response.json();
    return utilisateur;
}  

export const postAddProductToCartService = (product, token) =>
  axios.post(
    CART_URL,
    { product },
    {
      headers: {
        authorization: token,
      },
    }
  );

export const postUpdateProductQtyCartService = (productId, type, token) =>
  axios.post(
    `${CART_URL}/${productId}`,
    {
      action: {
        type,
      },
    },
    {
    headers: {
        authorization: token,
    },
});

export const deleteProductFromCartService = (productId, token) =>
  axios.delete(`${CART_URL}/${productId}`, {
    headers: {
      authorization: token,
    },
});

export const getWishlistItemsService = (token) =>
  axios.get(WISHLIST_URL, {
    headers: {
      authorization: token,
    },
});

export const postAddProductToWishlistService = (product, token) =>
  axios.post(
    WISHLIST_URL,
    { product },
    {
      headers: {
        authorization: token,
      },
    }
);

export const deleteProductFromWishlistService = (productId, token) =>
  axios.delete(`${WISHLIST_URL}/${productId}`, {
    headers: {
      authorization: token,
    },
});

export async function getUserConnected(){
  const response=await fetch(CURRENT_USER_CONNECTED_URL, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
      }
  });
  const utilisateur = await response.json();
  return utilisateur;
}  

export const getAllCategoriesService = () => axios.get(CATEGORIES_URL);
